export enum FETCH_TYPE {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export const genericFetch = function <T> (url: string, data?: any, fetchType: FETCH_TYPE = FETCH_TYPE.GET) {
  const formData = new FormData()
  formData.append('json', JSON.stringify(data))
}

export default class Fetch {
  get<T> (url: string): Promise<T> {
    return fetch(url).then(response => {
      return new Promise<T>(resolve => {
        response.json().then(data => {
          resolve(data)
        }).catch(onerror => console.error(onerror))
      })
    })
  }

  post<T> (url: string, data: any): Promise<T> {
    return fetch(url, this.getRequest(data, FETCH_TYPE.POST)).then(response => {
      return new Promise<T>(resolve => {
        response.json().then(data => {
          resolve(data)
        })
      })
    })
  }

  put<T> (url: string, data: any): Promise<T> {
    return fetch(url, this.getRequest(data, FETCH_TYPE.PUT)).then(response => {
      return new Promise<T>(resolve => {
        response.json().then(data => {
          resolve(data)
        })
      })
    })
  }

  private getFormData (data?: any) {
    const formData = new FormData()
    formData.append('json', JSON.stringify(data))
    return formData
  }

  private getRequest (data?: any, fetchType?: FETCH_TYPE): RequestInit {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: fetchType,
      body: JSON.stringify(data)
    }
  }
}
