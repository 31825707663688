





























import { Component } from 'vue-property-decorator'
import Footer from '@/components/footer/Footer.vue'
import Header from '@/components/header/Header.vue'
import Base from '@/application/models/Base'
import { mapActions } from 'vuex'
import { Action, Getter } from 'vuex-class'
import Hospital from '@/application/models/Hospital'

@Component({
  components: { Header, Footer },
  methods: mapActions({
    fetchData: 'FETCH_DATA'
  })
})

export default class App extends Base {
  @Action('FETCH_DATA')
  fetchData!: () => Promise<boolean>

  @Getter('selectHospital', { namespace: 'hospitals' })
  readonly selectHospital!: Hospital

  public isLoadingData

  constructor () {
    super('app')
    this.isLoadingData = true
  }

  created () {
    console.log(this.$store)
    this.fetchData().then(() => {
      this.isLoadingData = false
    })
  }
}
