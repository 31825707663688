import { Vue } from 'vue-property-decorator'

export default class Base extends Vue {
  private langPrefix: string

  constructor (langPrefix: string) {
    super()
    this.langPrefix = langPrefix
  }

  protected getLangText (path: string, data = ''): string {
    return this.getLangTextWithoutPrefix(this.langPrefix + '.' + path, data)
  }

  protected getLangTextWithoutPrefix (path: string, data = ''): string {
    return this.$t(path, data).toString()
  }

  protected prettyDate (rawDate: any) {
    if (!rawDate) return ''
    const date = new Date(rawDate)
    return `${date.toLocaleTimeString()}/ ${date.toLocaleDateString()}`
  }

  protected get generalRules () {
    return {
      notEmpty: [
        (input: string) => !!input || this.getLangTextWithoutPrefix('app.form.general.notEmpty')
      ]
    }
  }
}

export interface Form {
  validate(): boolean;
  reset(): void;
}
