









import { Component } from 'vue-property-decorator'
import Base from '@/application/models/Base'

@Component({})
export default class Footer extends Base {
  constructor () {
    super('app.footer')
  }

  get navIcons (): NavIcon[] {
    return [
      { label: 'dashboard', icon: 'mdi-heart-pulse', path: '/' },
      { label: 'global-limits', icon: 'mdi-alert', path: '/thresholds' },
      { label: 'settings', icon: 'mdi-cog', path: '/settings' },
      { label: 'manage', icon: 'mdi-account-group', path: '/manage' }
    ]
  }
}

export interface NavIcon {
  label: string;
  icon: string;
  path: string;
}
