import { Module } from 'vuex'
import { RootState } from '@/application/store/RootStore'
import AppSetting from '@/application/models/AppSetting'

export const AppSettingModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    appSetting: AppSetting
  },

  getters: {
    appSetting: ({ appSetting }) => appSetting
  },

  mutations: {
    appSetting: (state, newAppSetting: AppSetting) => {
      state.appSetting = newAppSetting
    }
  }
}
