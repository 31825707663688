export enum VitalSignType {
  PULSE_RATE = 'PULSE_RATE',
  OXYGEN_SATURATION = 'OXYGEN_SATURATION',
  BODY_TEMPERATURE = 'BODY_TEMPERATURE'
}

export default class Thresholds {
  private global = new Array<Threshold>();

  constructor () {
    this.global.push({ vitalSignType: VitalSignType.PULSE_RATE, minValue: 40, maxValue: 50 })
    this.global.push({ vitalSignType: VitalSignType.OXYGEN_SATURATION, minValue: 95, maxValue: 100 })
    this.global.push({ vitalSignType: VitalSignType.BODY_TEMPERATURE, minValue: 36, maxValue: 38 })
  }

  getThreshold (type: VitalSignType): Threshold {
    return this.global.find(t => t.vitalSignType === type)!
  }

  isReachedThreshold (type: VitalSignType, value: number): boolean {
    const threshold = this.getThreshold(type)
    return value > threshold.maxValue || value < threshold.minValue
  }
}

export interface Threshold {
  id?: string;
  vitalSignType: VitalSignType;
  minValue: number;
  maxValue: number;
}
