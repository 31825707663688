import Device from '@/application/models/Device'
import Patient from '@/application/models/Patient'
import Hospital from '@/application/models/Hospital'
import { UrlBuilder } from '@/application/models/UrlBuilder'
import PatientVitalsSources from '@/application/models/PatientVitalsSources'
import { Threshold } from '@/application/models/Thresholds'
import { VitalType } from '@/application/models/Vital'
import Fetch from '@/application/store/Fetch'
import PatientDataProvider from '@/application/models/PatientDataProvider'

const urlBuilder = new UrlBuilder()
const fetch = new Fetch()

export const BACKEND_API = {
  REALTIME: {
    WRISTBANDS: (vitalType: VitalType) => new EventSource(urlBuilder.vitals(vitalType))
  },
  REST: {
    // Hospitals
    HOSPITALS: fetch.get<Array<Hospital>>(urlBuilder.hospitals()),
    POST_HOSPITAL: (hospital: Hospital) => fetch.post<Hospital>(urlBuilder.hospitals(), hospital),
    // Patients
    GET_PATIENTS: (patientProviderID: string) => fetch.get<Array<PatientVitalsSources>>(urlBuilder.patiens(patientProviderID)),
    GET_PATIENT: (patientDataProviderId: string, patientDataProviderPatientId: string) => fetch.get<Patient>(urlBuilder.patient(patientDataProviderId, patientDataProviderPatientId)),
    GET_ADMIT_PATIENTS: (hospitalId: string) => fetch.get<Array<PatientVitalsSources>>(urlBuilder.admitedPatiens(hospitalId)),
    POST_PATIENTS: (patient: Patient) => fetch.post<Patient>(urlBuilder.postPatiens(patient.dataProvider.id, patient.dataProviderPatientId), patient),
    ADMIT_PATIENT: (hospitalId: string, patientId: string) => fetch.post(urlBuilder.adminPatient(hospitalId, patientId), {}),
    DISCHARGE_PATIENT: (hospitalId: string, patientId: string) => fetch.post(urlBuilder.dischargePatient(hospitalId, patientId), {}),

    PATIENT_VITAL_SOURCES: (patientId: string) => fetch.get<Array<PatientVitalsSources>>(urlBuilder.patientActiveVitalsSource(patientId)),

    THRESHOLDS: (hospitalID: string) => fetch.get<Array<Threshold>>(urlBuilder.hosptialThresholds(hospitalID)),

    PATIENT_DATA_PROVIDER: () => fetch.get<Array<PatientDataProvider>>(urlBuilder.patientDataProviders()),
    POST_PATIENT_DATA_PROVIDER: (patientDataProvider: PatientDataProvider) => fetch.post<PatientDataProvider>(urlBuilder.patientDataProviders(), patientDataProvider),

    DEVICES: new Promise<Array<Device>>(resolve => {
      console.warn('WARNING: Device are fakedata')
      const devices = Array<Device>()
      for (let i = 0; i < 15; i++) {
        devices.push({
          id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
          status: { battery: Math.floor(Math.random() * Math.floor(100)).toString() }
        })
      }
      resolve(devices)
    })

  }
}
