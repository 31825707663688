import { Module } from 'vuex'
import { RootState } from '@/application/store/RootStore'
import { BACKEND_API } from '@/application/store/BackendAPI'
import Thresholds, { Threshold, VitalSignType } from '@/application/models/Thresholds'

export const ThresholdsModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    thresholds: Array<Threshold>()
  },

  getters: {
    thresholds: ({ thresholds }) => thresholds,
    threshold: ({ thresholds }, id: string) => thresholds.find((threshold: Threshold) => threshold.id === id)!
  },

  mutations: {
    thresholds: (state, newThreshholds: Array<Threshold>) => {
      console.log('mutation of thresholds ', newThreshholds)
      state.thresholds = newThreshholds
    }
  },

  actions: {
    FETCH_THRESHOLDS_DATA: ({ commit }, hospitalId: string) => {
      return new Promise<Array<Threshold>>(resolve => {
        BACKEND_API.REST.THRESHOLDS(hospitalId).then((thresholds: Array<Threshold>) => {
          if (thresholds) {
            thresholds = new Array<Threshold>()
            thresholds.push({
              vitalSignType: VitalSignType.BODY_TEMPERATURE,
              minValue: 34.0,
              maxValue: 37.5
            })

            thresholds.push({
              vitalSignType: VitalSignType.PULSE_RATE,
              minValue: 30,
              maxValue: 55
            })

            thresholds.push({
              vitalSignType: VitalSignType.OXYGEN_SATURATION,
              minValue: 0.95,
              maxValue: 1
            })
          }
          commit('thresholds', thresholds)
          console.log('thresholds ', thresholds)
          resolve(thresholds)
        }).catch(error => console.log(error))
      })
    }
  }
}
