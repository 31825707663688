import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import messages from './application/lang/lang'
import './registerServiceWorker'
import router from './application/router/router'
import vuetify from './application/theme/vuetify'
import VueApexCharts from 'vue-apexcharts'
import store from './application/store/RootStore'

Vue.config.productionTip = false

/** use **/
Vue.use(VueI18n)
Vue.use(VueApexCharts)

/** preDefiened Objects **/
export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages
})

/** gobal bind from Components **/
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
