import { Module } from 'vuex'
import { RootState } from '@/application/store/RootStore'
import { BACKEND_API } from '@/application/store/BackendAPI'
import PatientVitalsSources from '@/application/models/PatientVitalsSources'

export const VitalSourcesModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    patientVitalsSources: Array<PatientVitalsSources>()
  },

  getters: {
    patientVitalsSources: ({ patientVitalsSources }) => patientVitalsSources
  },

  mutations: {
    patientVitalsSources: (state, newPatientVitalsSources: Array<PatientVitalsSources>) => {
      state.patientVitalsSources = newPatientVitalsSources
    },
    addPatientVitalsSources: (state, newPatientVitalsSources: Array<PatientVitalsSources>) => {
      newPatientVitalsSources.forEach((p: PatientVitalsSources) => state.patientVitalsSources.push(p))
    }
  },

  actions: {
    FETCH_PATIENTVITALSSOURCES_DATA: ({ state }, patientId: string) => {
      return BACKEND_API.REST.PATIENT_VITAL_SOURCES(patientId)
    }
  }
}
