import { VitalType } from '@/application/models/Vital'

export enum BaseURL {
  ORGANIZATIONAL_SERVICE = 'https://chm-organizational-service.ext.birkle-it.net/api',
  VITALS_SERVICE = 'https://chm-vitals-service.ext.birkle-it.net/api',
}

export class UrlBuilder {
  private url

  constructor () {
    this.url = ''
  }

  public hospitals (): string {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals`
  }

  public patiens (patientProviderID: string): string {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/patientDataProviders/${patientProviderID}/patients`
  }

  public patient (patientDataProviderId: string, patientDataProviderPatientId: string): string {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/patientDataProviders/${patientDataProviderId}/patients/${patientDataProviderPatientId}`
  }

  public admitedPatiens (hospitalId: string): string {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals/${hospitalId}/patients`
  }

  public postPatiens (patientProviderId: string, patientDataProviderPatientId: string): string {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/patientDataProviders/${patientProviderId}/patients/${patientDataProviderPatientId}`
  }

  public adminPatient (hospitalId: string, patientId: string): string {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals/${hospitalId}/patients/${patientId}/admit`
  }

  public dischargePatient (hospitalId: string, patientId: string): string {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals/${hospitalId}/patients/${patientId}/discharge`
  }

  public patientActiveVitalsSource (patientID: string) {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/patients/${patientID}/vitals/sources/active`
  }

  public patientVitalsSource (patientID: string) {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/patients/${patientID}/vitals/sources`
  }

  public patientVitalsStream (vitalsSourcesID: number) {
    return `${BaseURL.VITALS_SERVICE}/vitalsSources/${vitalsSourcesID}/vitals?startTime=${new Date(Date.now() - 360000).toISOString()}`
  }

  public hosptialThresholds (hospitalID: string) {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/hospitals/${hospitalID}/vitals/thresholds`
  }

  public vitals (vitalType: VitalType) {
    return `${BaseURL.VITALS_SERVICE}/vitalsSources/2/vitals/${vitalType}?mock=true`
  }

  public patientDataProviders () {
    return `${BaseURL.ORGANIZATIONAL_SERVICE}/patientDataProviders`
  }
}
