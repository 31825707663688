export default interface Vital {
  type: VitalType;
  value: any;
  timestamp: any;
}

export enum VitalType {
  PULSE_RATE = 'PULSE_RATE',
  OXYGEN_SATURATION = 'OXYGEN_SATURATION',
  BODY_TEMPERATURE = 'BODY_TEMPERATURE'
}
