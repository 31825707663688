















































import { Component } from 'vue-property-decorator'
import Base from '@/application/models/Base'
import Hospital from '@/application/models/Hospital'
import { Action, Getter } from 'vuex-class'
import Patient from '@/application/models/Patient'
import { Threshold } from '@/application/models/Thresholds'
import Tooltip from '@/components/common/Tooltip.vue'

@Component({
  components: { Tooltip }
})
export default class Header extends Base {
  // control
  selectedHospitalDialog = false

  @Getter('hospitals', { namespace: 'hospitals' })
  storeHospitals!: Array<Hospital>

  @Action('FETCH_DATA', { namespace: 'patients' })
  readonly fetchPatientsData!: (patientDataProviderId: string) => Promise<Array<Array<Patient>>>

  @Action('FETCH_PATIENTVITALSSOURCES_DATA', { namespace: 'vitalSources' })
  readonly fetchPatientvitalssourcesData!: (patientID: string) => Promise<any>

  @Action('FETCH_THRESHOLDS_DATA', { namespace: 'thresholds' })
  readonly fetchThresholdsData!: (hospitalId: string) => Promise<Threshold>

  public selectedHospital: Hospital = { id: '0', patientDataProvider: { id: '0', name: '' } }

  constructor () {
    super('app.header')
  }

  mounted () {
    if (this.selectedHospital.id === '0') {
      this.selectedHospitalDialog = true
    }
  }

  public onChangeHospital () {
    if (this.selectedHospital && this.selectedHospital.patientDataProvider) {
      console.log(this.selectedHospital)
      this.fetchPatientsData(this.selectedHospital.id).then((patientsList: Array<Array<Patient>>) => {
        if (patientsList.length >= 2) {
          patientsList[1].forEach(patient => {
            this.fetchPatientvitalssourcesData(patient!.id!)
          })
        }
      })
      this.$store.commit('hospitals/selectHospital', this.selectedHospital)
      this.fetchThresholdsData(this.selectedHospital.id)
      // this.$store.dispatch('thresholds/FETCH_THRESHOLDS_DATA', this.selectedHospital.id)
      this.selectedHospitalDialog = false
    }
  }

  get mapHospitals () {
    console.log(this.storeHospitals)
    return this.storeHospitals.map((h: Hospital) => {
      return {
        text: h.name,
        value: h
      }
    })
  }
}

