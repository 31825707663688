import { Module } from 'vuex'
import { RootState } from '@/application/store/RootStore'
import { BACKEND_API } from '@/application/store/BackendAPI'
import Device from '@/application/models/Device'
import PatientDataProvider from '@/application/models/PatientDataProvider'
import Hospital from '@/application/models/Hospital'

export const PatientDataProviderModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    patientDataProvider: Array<PatientDataProvider>()
  },

  getters: {
    patientDataProvider: ({ patientDataProvider }) => patientDataProvider
  },

  mutations: {
    patientDataProvider: (state, newPatientDataProvider: Array<PatientDataProvider>) => {
      console.log('mutation patientDataProvider ', newPatientDataProvider)
      state.patientDataProvider = newPatientDataProvider
    }
  },

  actions: {
    FETCH_PATIENTDATAPROVIDER_DATA: ({ commit }) => {
      return BACKEND_API.REST.PATIENT_DATA_PROVIDER().then((patientDataProviders: Array<PatientDataProvider>) => {
        commit('patientDataProvider', patientDataProviders)
        console.log(patientDataProviders)
        return patientDataProviders
      })
    },
    CREATE_PATIENTDATAPROVIDER: ({ dispatch }, patientDataProvider: PatientDataProvider) => {
      return BACKEND_API.REST.POST_PATIENT_DATA_PROVIDER(patientDataProvider).then((patientDataProvider: PatientDataProvider) => {
        dispatch('FETCH_PATIENTDATAPROVIDER_DATA')
        return patientDataProvider
      })
    }
  }
}
