import { Module } from 'vuex'
import { RootState } from '@/application/store/RootStore'
import { BACKEND_API } from '@/application/store/BackendAPI'
import Vital, { VitalType } from '@/application/models/Vital'

export const Vitals: Module<any, RootState> = {
  namespaced: true,
  state: {
    pulseRateVitals: Array<Vital>(),
    oxygenSaturationVitals: Array<Vital>(),
    bodyTemperatureVitals: Array<Vital>(),
    cleanUpTime: 30000
  },

  getters: {
    pulseRateVitals: ({ pulseRateVitals }) => pulseRateVitals,
    oxygenSaturationVitals: ({ oxygenSaturationVitals }) => oxygenSaturationVitals,
    bodyTemperatureVitals: ({ bodyTemperatureVitals }) => bodyTemperatureVitals
  },

  mutations: {
    pulseRateVitals: (state, newPulseRateVitals: Array<Vital>) => {
      state.pulseRateVitals = newPulseRateVitals
    },
    addPulseRateVital: (state, newPulseRateVital: Vital) => {
      state.pulseRateVitals.push(newPulseRateVital)
    },
    oxygenSaturationVitals: (state, newOxygenSaturationVitals: Array<Vital>) => {
      state.oxygenSaturationVitals = newOxygenSaturationVitals
    },
    addOxygenSaturationVitals: (state, newOxygenSaturationVital: Vital) => {
      state.oxygenSaturationVitals.push(newOxygenSaturationVital)
    },
    bodyTemperatureVitals: (state, newBodyTemperatureVitals: Array<Vital>) => {
      state.bodyTemperatureVitals = newBodyTemperatureVitals
    },
    addBodyTemperatureVitals: (state, newBodyTemperatureVital: Vital) => {
      state.bodyTemperatureVitals.push(newBodyTemperatureVital)
    },
    cleanup: (state) => {
      state.pulseRateVitals = []
      state.bodyTemperatureVitals = []
      state.oxygenSaturationVitals = []
    }
  },

  actions: {
    FETCH_VITALS_DATA: ({ commit, state }) => {
      /**
       BACKEND_API.REALTIME.WRISTBANDS.onerror = function (error: Event) {
        console.error(error.type)
      }
       BACKEND_API.REALTIME.WRISTBANDS.onopen = () => {
        console.log('connecting to backendapi ...')
      }**/
      window.setInterval(() => {
        commit('cleanup')
      }, state.cleanUpTime)

      BACKEND_API.REALTIME.WRISTBANDS(VitalType.PULSE_RATE).onmessage = function (event) {
        if (event.data !== 'ping') {
          const data = JSON.parse(event.data)
          commit('addPulseRateVital', data)
        }
      }
      BACKEND_API.REALTIME.WRISTBANDS(VitalType.BODY_TEMPERATURE).onmessage = function (event) {
        if (event.data !== 'ping') {
          const data = JSON.parse(event.data)
          commit('addBodyTemperatureVitals', data)
        }
      }
      BACKEND_API.REALTIME.WRISTBANDS(VitalType.OXYGEN_SATURATION).onmessage = function (event) {
        if (event.data !== 'ping') {
          const data = JSON.parse(event.data)
          commit('addOxygenSaturationVitals', data)
        }
      }
    }
  }
}
