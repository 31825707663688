











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Tooltip extends Vue {
  @Prop({ required: true, default: '' })
  text!: string
}
