import Hospital from '@/application/models/Hospital'
import { RootState } from '@/application/store/RootStore'
import { BACKEND_API } from '@/application/store/BackendAPI'
import { Module } from 'vuex'

export const HospitalsModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    hospitals: Array<Hospital>(),
    selectedHospital: {}
  },

  getters: {
    hospitals: ({ hospitals }) => hospitals,
    hospital: ({ hospitals }) => (id: string) => hospitals.find((h: Hospital) => h.id === id)!,
    selectHospital: ({ selectedHospital }) => selectedHospital
  },

  mutations: {
    hospitals: (state, newHospitals: Array<Hospital>) => {
      state.hospitals = newHospitals
    },
    addHospitals: (state, newHospital: Hospital) => {
      state.hospitals.push(newHospital)
    },
    selectHospital: (state, hospital: Hospital) => {
      state.selectedHospital = hospital
    }
  },

  actions: {
    FETCH_HOSPITALS_DATA: ({ commit }) => {
      return new Promise<Array<Hospital>>(resolve => {
        BACKEND_API.REST.HOSPITALS.then((hospitals: Array<Hospital>) => {
          commit('hospitals', hospitals)
          console.log('hospitals ', hospitals)
          resolve(hospitals)
        })
      })
    },
    CREATE_HOSPITAL: ({ dispatch }, hospital: Hospital) => {
      return BACKEND_API.REST.POST_HOSPITAL(hospital).then((hospital: Hospital) => {
        dispatch('FETCH_HOSPITALS_DATA')
        return hospital
      })
    }
  }
}
