import Vue from 'vue'
import Vuex from 'vuex'
import { HospitalsModule } from '@/application/store/modules/HospitalsModule'
import { PatientsModule } from '@/application/store/modules/PatientsModule'
import { Devices } from '@/application/store/modules/Devices'
import { ThresholdsModule } from '@/application/store/modules/ThresholdsModule'
import Hospital from '@/application/models/Hospital'
import Patient from '@/application/models/Patient'
import Device from '@/application/models/Device'
import { Threshold } from '@/application/models/Thresholds'
import PatientVitalsSources from '@/application/models/PatientVitalsSources'
import { VitalSourcesModule } from '@/application/store/modules/VitalSourcesModule'
import Vital from '@/application/models/Vital'
import { Vitals } from '@/application/store/modules/Vitals'
import { AppSettingModule } from '@/application/store/modules/AppSettingModule'
import AppSetting from '@/application/models/AppSetting'
import { PatientDataProviderModule } from '@/application/store/modules/PatientDataProviderModule'
import PatientDataProvider from '@/application/models/PatientDataProvider'

Vue.use(Vuex)

export interface RootState {
  root: boolean;
  hospitals: Array<Hospital>;
  patients: Array<Patient>;
  devices: Array<Device>;
  thresholds: Array<Threshold>;
  vitalSources: Array<PatientVitalsSources>;
  vitals: {
    pulseRateVitals: Array<Vital>;
    oxygenSaturationVitals: Array<Vital>;
    bodyTemperatureVitals: Array<Vital>;
  };
  appSetting: AppSetting;
  patientDataProvider: Array<PatientDataProvider>;
}

export default new Vuex.Store<RootState>({
  modules: {
    hospitals: HospitalsModule,
    patients: PatientsModule,
    devices: Devices,
    thresholds: ThresholdsModule,
    vitalSources: VitalSourcesModule,
    vitals: Vitals,
    appSetting: AppSettingModule,
    patientDataProvider: PatientDataProviderModule
  },
  actions: {
    FETCH_DATA ({ dispatch }): Promise<boolean> {
      return new Promise<boolean>(resolve => {
        Promise.all([
          dispatch('hospitals/FETCH_HOSPITALS_DATA'),
          dispatch('devices/FETCH_DEVICES_DATA'),
          dispatch('vitals/FETCH_VITALS_DATA')
        ]).then(() => resolve(true))
      })
    }
  }
})
